import React, { useEffect, useState } from "react";
// import './Common.css'
// import { importAllImages } from '../Helper.js'
// import { _submitLeadWithCallBack, _getCurrentFirebaseTime, _getProjectLink, _getSubId, _submitLeadDistDBWithCallBack, _isProjectOnHold, postLead } from '../Firebase';
import PincodeLoader from "./PincodeLoader";
import * as States from "./States";
import { postCustLead, checkPincode } from "../Firebase";
import Spinner from "./Loader";
import Error from "./Error";

export default function PanLeadForm(props) {
  const project = props.projectName;
  const logo = props.projectLogo;
  const db = props.db;

  document.title = project;
  // var projectLogo = project + '.png';
  const [state, setState] = useState("init");

  const formElements = {
    name: "",
    pan: "",
    mobile: "",
    email: "",
    pincode: "",
  };
  const [formData, setFormData] = useState(formElements);
  const [formErrors, setFormErrors] = useState({
    name: "",
    pan: "",
    mobile: "",
    email: "",
    pincode: "",
  });
  const [isLoadingPincode, setIsLoadingPincode] = useState(false);
  const [isPincodeVerified, setIsPincodeVerified] = useState(false);

  //   console.log("props ", project , " ", projectLogo, " aUid ", agentUid);

  // const images = importAllImages(require.context('../images', false, /\.(png|jpe?g|svg)$/));

  useEffect(() => {}, []);

  const fetchPincodeData = (pincode) => {
    const name = "pincode";
    setIsLoadingPincode(true);
    checkPincode(pincode, (res) => {
      if (res.valid) {
        setFormErrors({ ...formErrors, [name]: "" });
        setIsLoadingPincode(false);
        setIsPincodeVerified(true);
      } else {
        setFormErrors({ ...formErrors, [name]: States.INVALID_PINCODE });
        setIsLoadingPincode(false);
        setIsPincodeVerified(false);
      }
    });
  };

  const handlePincodeChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (/^\d{6}$/.test(value)) {
      fetchPincodeData(value);
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
      setIsPincodeVerified(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "pan") {
      setFormData({ ...formData, [name]: value.toUpperCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }

    if (formData.name !== "") {
      setFormErrors({ name: "" });
    } else if (formData.mobile !== "") {
      setFormErrors({ mobile: "" });
    } else if (formData.email !== "") {
      setFormErrors({ email: "" });
    } 
    if(isPincodeVerified){
      setFormErrors({ pincode: "" });
    }
  };

  function submitLead(e) {
    e.preventDefault();

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regMob = /^[0-9\b]+$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (formData.name === "") {
      setFormErrors({ name: "Please enter full name" });
    } else if (formData.pan.length !== 10) {
      setFormErrors({ pan: "Please enter valid pan" });
    } else if (!panRegex.test(formData.pan)) {
      setFormErrors({ pan: "Please enter valid pan" });
    } else if (!regMob.test(formData.mobile)) {
      setFormErrors({ mobile: "Please enter valid mobile" });
    } else if (formData.mobile.length !== 10) {
      setFormErrors({ mobile: "Mobile number must have 10 digits" });
    } else if (formData.mobile.charAt(0) === "0") {
      setFormErrors({ mobile: "Mobile number cannot start with zero" });
    } else if (!regex.test(formData.email)) {
      setFormErrors({ email: "Please enter valid email" });
    } else if (formData.pincode.length !== 6 || formErrors.pincode !== "") {
      setFormErrors({ pincode: "Please enter valid pincode" });
    } else {
      // if (props.projectName.includes('Axis')) { project = 'Axis'; }

      const lead = {
        db: db,
        uid: props.aUid,
        customerName: formData.name,
        customerPan: formData.pan,
        customerMobile: formData.mobile,
        customerEmail: formData.email,
        customerPincode: formData.pincode,
        projectName: project,
        leadCategory: props.leadCategory,
        proceed: false,
      };

      setState("waiting");
      postCustLead(lead, function (res) {
        if (res.status === "success") {
          if (res.submitted === true && res.link !== null) {
            window.open(res.link, "_self");
          } else if (res.isLeadExist === true) {
          }
        } else {
          setState("error");
        }
      });
    }
  }

  if (state === "init") {
    return (
      <div>
        <div className="mainbox">
          <div className="header">
            <img src={logo} alt="" className="prodlogo" />
          </div>

          <form action="" method="post" id="leadform">
            <fieldset className="groupfield">
              <div className="field">
                <input
                  type="text"
                  placeholder="Full Name*"
                  name="name"
                  maxLength="25"
                  value={formData.name}
                  id="fullnameId"
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Enter Full Name")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  onChange={onInputChange}
                />
                <p className="error">{formErrors.name}</p>
              </div>
              <div className="field">
                <input
                  type="text"
                  placeholder="PAN*"
                  name="pan"
                  value={formData.pan}
                  id="pan"
                  required
                  maxLength="10"
                  onChange={onInputChange}
                />
                <p className="error">{formErrors.pan}</p>
              </div>
              <div className="field">
                <input
                  type="tel"
                  placeholder="Mobile*"
                  name="mobile"
                  value={formData.mobile}
                  id="mobileId"
                  required
                  maxLength="10"
                  pattern="[0-9]{10}"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Enter valid mobile")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  onChange={onInputChange}
                />
                <p className="error">{formErrors.mobile}</p>
              </div>
              <div className="field">
                <input
                  type="email"
                  placeholder="Email*"
                  name="email"
                  value={formData.email}
                  id="emailId"
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Enter valid Email")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  onChange={onInputChange}
                />
                <p className="error">{formErrors.email}</p>
              </div>
              <div className="field pincode-offline-container">
                <input
                  type="tel"
                  placeholder="Pincode*"
                  name="pincode"
                  value={formData.pincode}
                  id="pincodeId"
                  maxLength="6"
                  pattern="[0-9]{6}"
                  required
                  onInvalid={(e) =>
                    e.target.setCustomValidity("Enter valid pincode")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  onChange={handlePincodeChange}
                />
                {isLoadingPincode && (
                  <div className="pincode-offline-spinner">
                    <PincodeLoader />
                  </div>
                )}
                <p className="error">{formErrors.pincode}</p>
              </div>
              <button
                className="btn-submitlead"
                type="submit"
                onClick={submitLead}
                disabled={!isPincodeVerified}
              >
                Save & Proceed
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  } else if (state === "waiting") {
    return <Spinner />;
  } else if (state === "error") {
    return <Error />;
  }
}
